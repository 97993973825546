import React, { Component } from "react";

class Loader extends Component {
  render() {

    return (
        <div className="loader-body">
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
                {/* <span></span> */}
            </div>
            <span className="terra"></span>
        </div>
    );
  }
}

export default Loader;
