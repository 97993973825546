import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Loader from "./Components/Loader";
import Contact from "./Components/Contact";
import Modal from "./Components/Modal";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {},
      isLoading: true,
      show: false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }
  
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
    setTimeout(() => {
      this.setState({isLoading: false})
    }, 3000)
  }

  render() {
    return (
      <Router>
        {/* <Switch>
          <Route exact path='/' component={App} />
          <Route path='/Valentine' component={Valentine} />
        </Switch> */}
        <div className="App">
          {this.state.isLoading? <Loader /> : null}
          {/* <Loader /> */}
          <Modal className="modal-front" show={this.state.show} handleClose={this.hideModal}>
            <p>Modal</p>
          </Modal>
          <button className="click" onClick={this.showModal}>
            Click if you're jia chi
          </button>
          <Header data={this.state.resumeData.main} />
          <About data={this.state.resumeData.main} />
          <Resume data={this.state.resumeData.resume} />
          {/* <Portfolio data={this.state.resumeData.portfolio} /> */}
          <Contact data={this.state.resumeData.main} />
          <Footer data={this.state.resumeData.main} />
        </div>
      </Router>
    );
  }
}

export default App;
