// import './modal.css';
import React, { Component } from "react";


class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        if(this.state.value === '140316') {
            alert('Hi, my love. Just like to apologise for this flimsy programme. I wanted to do more for you, but oh how my computing skills have deteriorated. I spent hours debugging and trying some fancy stuff to no avail :(. So hopefully, this can suffice for now, and I will try to do a fancier one some time in the future ;) Next, close this and retype in the last 4 numbers of my IC')
            // return <Redirect to='/valentine' />
            // return <Redirect to='/Valentine' />
        } else if(this.state.value === '5277') {
            alert('Hello love. Hope you enjoyed the flower. Sad that we have to spend valentiens separately, and hopefully not very painful today for you. I miss you dearly and hope all is well there. Just wanted to say thanks for being such an amazing girlfriend. Times have been tough and I really appreciate you being there for me. Similarly, I will always be there for you as well. To see some extra 废材 stuff, can try typing in the first 6 number of my IC')
        }   else if(this.state.value === '971226') {
            alert(' Sorry I don\'t rmb all the details like you do and I\'m quite forgetful sometimes, hopefully you can 包容包容 abit on that end. Know that I won\'t be mad also if you don\'t rmb my stuff ;) hahaha. Anyways, just glad that we\'re together, love you mauckz muackz. The end.')
        }   else if(this.state.value === '060799') {
            alert('This is not my official message, but nice try, of course your birthday will show some form of message.')
        }   else {
            alert('nice try');
        }
        event.preventDefault();
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }
    render(){
        const showHideClassName = this.props.show ? "modal display-block" : "modal display-none";

        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    <button className="close" onClick={this.props.handleClose} >X</button>
                    <form onSubmit={this.handleSubmit}>
                        <h5 className='password'>PASSWORDS:</h5>
                        <label>
                            <input type="text" className='inputcenter' value={this.state.value} onChange={this.handleChange} />
                        </label>
                        <input type="submit" value="Submit" />
                    </form>
                </section>
            </div>
        );
    }
}

export default Modal;

